import "./App.css";
import {
  Box,
  Button,
  createTheme,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
} from "@mui/material";
import logo from "./img/logo.svg";
import { useState, useEffect } from "react";
import img1 from "./img/2.webp";
import icons from "./constant/icons";
import { CiCircleChevRight } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import appStore from "./img/appstore.svg";
import playStore from "./img/googlestore.svg";
import { IoIosArrowRoundDown } from "react-icons/io";
import Aos from "aos";
import "aos/dist/aos.css";
import { HashLink } from "react-router-hash-link";
import ConfettiExplosion from "react-confetti-explosion";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1D69D8",
    },
    secondary: {
      main: "#3EC28B",
    },
  },
  typography: {
    fontFamily: "outfit",
  },
});

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

function App() {
  const [animate, setAnimate] = useState(false);

  const mobileTheme = useTheme();

  const isMobile = useMediaQuery(mobileTheme.breakpoints.down("md"));

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    // Trigger the animation after the component mounts
    setAnimate(true);
  }, []);

  const [response, setResponse] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userType: "",
  });

  const { firstName, lastName, email, userType } = form;

  const [isLoading, setIsLoading] = useState(false);

  const [isSuccessful, setIsSuccessful] = useState(false);

  const addToWaitList = async () => {
    setIsSuccessful(false);
    setIsLoading(true);
    try {
      const result = await fetch("/api/waitList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          emailAddress: email,
          profileType: userType,
        }),
      });

      if (!result.ok) {
        throw new Error(`Error: ${result.status}`);
      }
      setIsLoading(false);

      setIsSuccessful(true);
      const data = await result.json();
      setResponse(data);
      console.log("Success:", data);
    } catch (error) {
      setIsSuccessful(false);
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setForm({
      ...form,
      email: inputEmail,
    });

    // Validate email and set error message if invalid
    if (inputEmail && !validateEmail(inputEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        setIsSuccessful(false);
      }, 4000);
    }
  }, [isSuccessful]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: { lg: "90vh", md: "90vh", sm: "90vh", xs: "100vh" },
            background: "#1D69D8",
            backgroundImage: `url(${icons.hero_bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "90%" },
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                columnGap: 1.7,
                pt: { lg: 13, md: 13, sm: 10, xs: 8 },
             justifyContent:isMobile && "center"
              }}
            >
              <img src={logo} alt="Golvia_logo" width={40} />

              <Box
                sx={{ overflowX: "hidden", height: "40px" }}
                className="hide_scrollbar"
              >
                <Typography
                  className={`text-container ${animate ? "animate" : ""}`}
                  sx={{
                    fontSize: "30px",
                    fontWeight: 500,
                    height: "100%",
                    color: "#fff",
                  }}
                >
                  Golvia
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 3,
                justifyContent: isMobile ? "center" : "space-between",
            
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
              }}
            >
              <Box
              align={isMobile && "center"}
                sx={{
                  width: { lg: "60%", md: "60%", sm: "100%", xs: "100%" },
                  my: 5,
                }}
              >
                <img src={icons.com_soon} width={"30%"} />

                <Typography
                  variant="h1"
                  sx={{
                    color: "#fff",
                    fontSize: {
                      lg: "48px",
                      md: "48px",
                      sm: "38px",
                      xs: "35px",
                    },
                    fontWeight: 500,
                    mt: 4,
                  }}
                  className="animate four"
                >
                  The one social network
                 for <span>a</span>
                  <span>t</span>
                  <span>h</span>
                  <span>l</span>
                  <span>e</span>
                  <span>t</span>
                  <span>e</span>
                  <span>s,</span> <span>s</span>
                  <span>c</span>
                  <span>o</span>
                  <span>u</span>
                  <span>t</span> , <span>c</span>
                  <span>l</span>
                  <span>u</span>
                  <span>b</span>
                  <span>s</span> and <span>f</span>
                  <span>a</span>
                  <span>n</span>
                  <span>s</span> to connect.
                </Typography>
                <HashLink
                  to="#get_started"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <Button
                    sx={{
                      mt: 4,
                      fontSize: "12px",
                      bgcolor: "#fff",
                      color: "#1B61C8",
                      textTransform: "initial",
                      borderRadius: "8px",
                      height: "50px",
                      width: "250px",
                    }}
                    endIcon={<CiCircleChevRight style={{ color: "#1B61C8" }} />}
                  >
                    Join the waiting list
                  </Button>
                </HashLink>
              </Box>
              <Box
                sx={{ width: { lg: "40%", md: "40%", sm: "70%", xs: "80%" } }}
                data-aos="fade-left"
              >
                <Box
                  sx={{
                    background: `url(${icons.board1})`,
                    height: {
                      lg: "600px",
                      md: "600px",
                      sm: "500px",
                      xs: "500px",
                    },
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box
                    sx={{
                      margin: "0 auto",
                      height: "100%",
                      mb: -20,
                      width: "100%",
                      p: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: { lg: 20, md: 20, sm: 15, xs: 10 },
                        mx: { lg: 0, md: 0, sm: -3, xs: -5 },
                      }}
                    >
                      <Box sx={{ ml: 0 }}>
                        <img src={icons.head1} width={50} className="image" />
                      </Box>
                      <Box sx={{ mt: 4 }}>
                        <img src={icons.head3} width={50} className="image" />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 18,
                      }}
                    >
                      <Box sx={{ ml: 0 }}>
                        <img src={icons.head2} width={50} className="image" />
                      </Box>
                      <Box sx={{ mt: 4 }}>
                        <img src={icons.head4} width={55} className="image" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "95%" },
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 10,
            pb: 8,
            flexDirection: {
              lg: "row",
              md: "row",
              sm: "column-reverse",
              xs: "column-reverse",
            },
          }}
        >
          <Box
            sx={{ width: { lg: "50%", md: "50%", sm: "80%", xs: "90%" } }}
            data-aos="fade-up"
            align={isMobile && "center"}
          >
            <img src={icons.board2} width={"70%"} />
          </Box>
          <Box

          align={isMobile && "center"}
            sx={{
              width: { lg: "40%", md: "40%", sm: "80%", xs: "90%" },
              mb: { lg: 0, md: 0, sm: 4, xs: 4 },
            }}
            data-aos="fade-right"
          >
            <Typography variant="h2" sx={{ fontSize: "30px", fontWeight: 600 }}>
              Our Mission
            </Typography>
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "14px", md: "14px", sm: "13px", xs: "13px" },
              }}
            >
         Our mission is to be The one social media platform for
athletes, scouts, clubs, and fans to connect
            </Typography>
            <HashLink
              to="#get_started"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <Button
                variant="contained"
                sx={{
                  mt: 6,
                  textTransform: "initial",
                  borderRadius: "8px",
                  height: "40px",
                  px: 5,
                }}
              >
                Join  the waiting list
              </Button>
            </HashLink>
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: "#EAF1FD",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontSize: { lg: "30px", md: "30px", sm: "27px", xs: "23px" },
              fontWeight: 600,
              mt: 7,
              mb: 3,
            }}
            data-aos="zoom-out"
          >
            Unforgettable <br />
            Sports Memories with Gol-Via
          </Typography>
          <img src={img1} width={"50%"} />
        </Box>
        <Box
          sx={{
            bgcolor: "primary.main",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "90%" },
              margin: "0 auto",
              display: "flex",
              pt: 10,
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column-reverse",
                xs: "column-reverse",
              },
            }}
          >
            <Box
              sx={{
                width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
                mb: -12,
              }}
              data-aos="fade-down"
              align={isMobile && "center"}
            >
              <img src={icons.board3} width={"60%"} />
            </Box>
            <Box
               align={isMobile && "center"}
              data-aos="fade-up"
              sx={{
                width: { lg: "40%", md: "40%", sm: "100%", xs: "100%" },
                mb: { lg: 0, md: 0, sm: 6, xs: 6 },

                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{ color: "#fff", fontWeight: 600, fontSize: "30px" }}
              >
                Get in the Game
                <br /> with Gol-Via
              </Typography>

              <Typography sx={{ color: "#fff", fontWeight: 400, mt: 3 }}>
        From one sport to all your favorite sports, we've got you covered. Stay up-to-date on the latest news and highlights.
              </Typography>

              <Box sx={{ mt: 3 }} id="get_started">
                <HashLink
                  to="#get_started"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <Button
                    sx={{
                      bgcolor: "#fff",
                      textTransform: "initial",
                      width: "200px",
                      borderRadius: "8px",
                      height: "40px",
                    }}
                  >
                    Join the waiting list
                  </Button>
                </HashLink>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* WAITING LIST FORM SECTION ==================================
=================================================== */}

        <Box sx={{ bgcolor: "#EAF1FD" }} id="get_started">
          <Box
            sx={{
              width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "90%" },
              margin: "0 auto",
              display: "flex",
              py: 5,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{ width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" } }}
            >
              {isSuccessful ? (
                <>
                  <Box
                    sx={{
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      <b style={{ fontSize: "30px" }}>Congrats!  🎉</b> <br />
                      You've been added to the waitlist. Stay tuned for updates!
                    </Typography>
                    <ConfettiExplosion
                      duration={6000}
                      zIndex={10000}
                      particleSize={7}
                      colors={[
                        "#1D69D8",
                        "#DA9500",
                        "#1D69D8",
                        "#FDC03C",
                        "#EAF1FD",
                      ]}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Join the  waiting list
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      width: { lg: "90%", md: "90%", sm: "100%", xs: "100%" },
                      mt: 4,
                    }}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box>
                        <InputLabel sx={{ color: "#3E3E3E", fontSize: "13px" }}>
                          First Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          value={firstName}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              firstName: e.target.value,
                            })
                          }
                          margin="dense"
                          placeholder="Enter Name"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#d4d4d4",
                                borderRadius: "9px",

                                color: "#3e3e3e",
                                // Default outline color
                              },
                            },
                          }}
                          slotProps={{
                            input: {
                              style: {
                                color: "#3e3e3e",
                                background: "#fff",
                                borderRadius: "9px",
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box>
                        <InputLabel sx={{ color: "#3E3E3E", fontSize: "13px" }}>
                          Last Name
                        </InputLabel>
                        <TextField
                          fullWidth
                          margin="dense"
                          value={lastName}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              lastName: e.target.value,
                            })
                          }
                          placeholder="Enter Name"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#d4d4d4",
                                borderRadius: "9px",

                                color: "#3e3e3e",
                                // Default outline color
                              },
                            },
                          }}
                          slotProps={{
                            input: {
                              style: {
                                color: "#3e3e3e",
                                background: "#fff",
                                borderRadius: "9px",
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                      <Box>
                        <InputLabel sx={{ color: "#3E3E3E", fontSize: "13px" }}>
                          Email Address
                        </InputLabel>
                        <TextField
                          fullWidth
                          value={email}
                          onChange={handleEmailChange}
                          error={!!emailError} // Adds error state if email is invalid
                          helperText={emailError}
                          margin="dense"
                          placeholder="Enter Email"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#d4d4d4",
                                borderRadius: "9px",

                                color: "#3e3e3e",
                                // Default outline color
                              },
                            },
                          }}
                          slotProps={{
                            input: {
                              style: {
                                color: "#3e3e3e",
                                background: "#fff",
                                borderRadius: "9px",
                                fontSize: "13px",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                      <Box>
                        <InputLabel sx={{ color: "#3E3E3E", fontSize: "13px" }}>
                          Join as
                        </InputLabel>
                        <TextField
                          fullWidth
                          value={userType}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              userType: e.target.value,
                            })
                          }
                          margin="dense"
                          placeholder="Enter Name"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#d4d4d4",
                                borderRadius: "9px",

                                color: "#3e3e3e",
                                // Default outline color
                              },
                            },
                          }}
                          select
                          slotProps={{
                            input: {
                              style: {
                                color: "#3e3e3e",
                                background: "#fff",
                                borderRadius: "9px",
                                fontSize: "13px",
                              },
                            },
                          }}
                        >
                          {selectTypes?.map((types, index) => (
                            <MenuItem key={index} value={types.value}>
                              {types?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {isLoading ? (
                        <>
                          <LinearProgress sx={{ height: "2px" }} />
                          <Typography
                            sx={{
                              mt: 0.5,
                              fontSize: "12px",
                              textAlign: "center",
                              color: "primary.main",
                              fontWeight: 600,
                            }}
                          >
                            Joining...
                          </Typography>
                        </>
                      ) : (
                        <Button
                          disabled={
                            !firstName ||
                            !lastName ||
                            !email ||
                            !userType ||
                            !!emailError
                          }
                          variant="contained"
                          fullWidth
                          onClick={addToWaitList}
                          sx={{
                            textTransform: "initial",
                            height: "45px",
                            borderRadius: "8px",
                          }}
                        >
                          Join list
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
            <Box
              sx={{
                width: "50%",
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
              }}
              align="right"
            >
              <img src={icons.board4} width="80%" />
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  columnGap: 3,
                  mt: 2,
                }}
              >
                <Button>
                  <img src={appStore} />
                </Button>
                <Button>
                  <img src={playStore} />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* FAQS=========================
        ================================ */}

        <Box sx={{ bgcolor: "#fff", py: 5 }}>
          <Box
            sx={{
              width: { xl: "50%", lg: "55%", md: "60%", sm: "90%", xs: "90%" },
              margin: "0 auto",
              mt: 4,
            }}
          >
            <Typography
              sx={{ fontWeight: 600, textAlign: "center", fontSize: "30px" }}
            >
              FAQs
            </Typography>

            <Box>
              {faqs.map((faq, index) => (
                <Accordion
                  sx={{
                    border: "0.5px solid #B4B4B4",
                    mb: 2,
                    borderRadius: "10px",
                    boxShadow: "none",
                    "&::before": { height: "0px" },
                  }}
                  key={index}
                >
                  <AccordionSummary
                    sx={{ borderRadius: "10px" }}
                    expandIcon={<IoIosArrowRoundDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography> {faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography> {faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>

        {/* FOOTER =======================================================
==================================================================== */}

        <Box
          sx={{
            bgcolor: "#EAF1FD",
            height: { lg: "200px", md: "200px", sm: "auto", xs: "auto" },
          }}
        >
          <Box
            sx={{
              width: { xl: "75%", lg: "85%", md: "85%", sm: "90%", xs: "95%" },
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              rowGap: 3,
              py: 4,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <img src={icons.logo} />
              <Typography variant="h5" sx={{ fontWeight: 600, color: "#000" }}>
                Golvia
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                Connecting athletes to opportunities worldwide
              </Typography>
              <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                All right reserved (c) 2024
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <IconButton
                sx={{ bgcolor: "#000", width: "45px", height: "45px" }}
              >
                <FaFacebookF style={{ color: "#fff", fontSize: "16px" }} />
              </IconButton>
              <IconButton
                sx={{ bgcolor: "#000", width: "45px", height: "45px" }}
              >
                <FaXTwitter style={{ color: "#fff", fontSize: "16px" }} />
              </IconButton>
              <IconButton
                sx={{ bgcolor: "#000", width: "45px", height: "45px" }}
              >
                <FaInstagram style={{ color: "#fff", fontSize: "16px" }} />
              </IconButton>
              <IconButton
                sx={{ bgcolor: "#000", width: "45px", height: "45px" }}
              >
                <FaLinkedinIn style={{ color: "#fff", fontSize: "16px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;

const selectTypes = [
  {
    label: "Athletes",

    value: "ATHLETES",
  },
  {
    label: "Scout",

    value: "SCOUT",
  },
  {
    label: "Club/Team",

    value: "TEAM",
  },
  {
    label: "Fanbase",

    value: "FANBASE",
  },
];

const faqs = [
  {
    question: "What is Golvia?",
    answer:
      "Golvia is a new social media platform specifically designed for athletes, coaches, academies, scouts, agents, sports enthusiasts, and fans. It aims to create a focused and supportive community where users can connect, showcase their talents, and collaborate without the distractions often found on traditional social media platforms. 'Gol-Via' means 'Goal Through' and signifies 'Score Your Dream Goal.'",
  },
  {
    question: "How is Golvia different from other social media platforms?",
    answer:
      "Unlike existing platforms that can be noisy and overwhelming, Golvia offers a tailored experience for the sports community. It prioritizes athlete development, networking opportunities, and meaningful interactions. Users can easily connect with other athletes, coaches, and sports organizations, fostering a community that supports growth and collaboration.",
  },
  {
    question: "Who can join Golvia?",
    answer:
      "Golvia is open to anyone involved in the sports community, including athletes, coaches, scouts, agents, sports businesses, and fans. Whether you're a professional athlete or just starting your journey in sports, Golvia provides a space for everyone.",
  },
  {
    question: "What features does Golvia offer?",
    answer:
      "Golvia includes features such as: Profile Creation (Showcase your skills, achievements, and sports journey), Networking Opportunities (Connect with coaches, scouts, and other athletes), Talent Showcasing (Share videos, photos, and updates about your sports activities), Collaboration Tools (Engage with fellow athletes and coaches for training, advice, and partnership opportunities), and a Marketplace (Connect with sports businesses for gear, training, and other resources).",
  },
  {
    question: "How do I create an account on Golvia?",
    answer:
      "To create an account, simply visit our website at www.gol-via.com or download the Golvia app from your app store. Follow the prompts to set up your profile and start connecting with the sports community.",
  },
  {
    question: "Is Golvia free to use?",
    answer:
      "Yes, Golvia offers a free membership option that provides access to basic features. We may also introduce premium features in the future for enhanced user experiences.",
  },
  {
    question: "How does Golvia ensure a safe and positive environment?",
    answer:
      "Golvia is committed to maintaining a safe and respectful environment. We implement community guidelines, moderation practices, and user reporting tools to address inappropriate behavior and ensure a positive experience for all users.",
  },
  {
    question: "Can businesses join Golvia?",
    answer:
      "Absolutely! Sports-related businesses can create profiles to connect with athletes and promote their products and services. This feature helps businesses reach their target audience within the sports community.",
  },
  {
    question: "How can I provide feedback or suggestions for Golvia?",
    answer:
      "We value user feedback! You can send your suggestions or inquiries through our contact form on the website or by reaching out to our support team at support@gol-via.com.",
  },
  {
    question: "When will Golvia be officially launched?",
    answer:
      "We are excited to announce that Golvia is set to launch on December 1, 2024. Stay tuned for updates and be among the first to join our community!",
  },
  {
    question: "Can monetization be added on Golvia?",
    answer:
      "Yes, monetization features will be added to Golvia in the future. We recognize the importance of providing opportunities for athletes and content creators to generate income from their engagements on the platform. This may include options like sponsored content, partnerships with brands, merchandise sales, and premium membership subscriptions. We are committed to evolving Golvia to meet the needs of our users and create a sustainable ecosystem that benefits the sports community. Stay tuned for updates on these exciting features!",
  },
];
